export const environment = {
  production: false,
  apiUrl: "https://Labs.api.deepersignals.com/api/",
  intercom: {
    enabled: true,
    api_url: "https://api-iam.intercom.io",
    app_id: "rn7d83f8",
  },
  ravenDsn: "",
  recaptchaSiteKey: "6LeMjJYnAAAAAOfFC-T6JC_Ll-f6MWQyjyfbbAGG",
  unlayerEmbedURL: "//editor.unlayer.com/embed.js",
  nonce: "RcK9WZrUnQ6HGKFUn33ACRGMuI97ZKWYW2HozREZVdhg2JAMMN0/jkj34ZpGeXLM",
  stripeKey: "pk_test_3Q2AaUWbNFKzWswNGMyORUUX00br8mVcUq",
  disableRecaptcha: false,
  enableHotjar: false,
  enableClarity: false,
  crowdinKey: "a0cc060927c83821c42dfe8qc3a",
  pixel: {
    enabled: false,
    trackOnLoad: false,
    key: "",
  },
};

export enum DsIconEnum {
  VerticalDots = "vertical-dots",
  User = "user",
  Email = "email",
  Lock = "lock",
  LockClosed = "lock-closed",
  UserCircle = "user-circle",
  DocumentDuplicate = "document-duplicate",
  DocumentReport = "document-report",
  Trash = "trash",
  UserAdd = "user-add",
  UserRemove = "user-remove",
  CloudDownload = "cloud-download",
  Clipboard = "clipboard",
  ChevronDown = "chevron-down",
  ChevronUp = "chevron-up",
  ChevronRight = "chevron-right",
  ChevronLeft = "chevron-left",
  Copy = "copy",
  Folder = "folder",
  FolderAdd = "folder-add",
  FolderOpen = "folder-open",
  ThumbUp = "thumb-up",
  ThumbDown = "thumb-down",
  Home = "home-blank",
  Code = "code",
  ArrowSmRight = "arrow-sm-right",
  ArrowSmLeft = "arrow-sm-left",
  ArrowRight = "arrow-right",
  Refresh = "refresh",
  Info = "info",
  Logout = "logout",
  Height = "height",
  PencilAlt = "pencil-alt",
  Minus = "minus",
  PlusSm = "plus-sm",
  Plus = "plus",
  ExclamationCircle = "exclamation-circle",
  ChartBar = "chart-bar",
  Calendar = "calendar",
  Assessment = "assessment",
  UserGroup = "user-group",
  CircleCheck = "circle-check",
  Pencil = "pencil",
  X = "x",
  Menu = "menu",
  UserGroups = "user-groups",
  DownloadOutline = "download-outline",
  Users = "users",
  Puzzle = "puzzle",
  LightBulb = "lightbulb",
  LightBulbDash = "light-bulb",
  CubeTransparent = "cube-transparent",
  ViewGrid = "view-grid",
  ChartPie = "chart-pie",
  Share = "share",
  DocumentText = "document-text",
  DocumentSearch = "document-search",
  Lines = "lines",
  ArrowBoth = "arrow-both",
  Academic = "academic",
  LongArrowRight = "long-arrow-right",
  ArrowNarrowRight = "arrow-narrow-right",
  Logo = "logo",
  LongerRightArrow = "longer-right-arrow",
  Eye = "eye",
  EyeSlash = "eye-slash",
  EyeOff = "eyeOff",
  ClipboardCheck = "clipboard-check",
  Achievement = "achievement",
  Change = "change",
  Expedience = "expedience",
  Humility = "humility",
  Independence = "independence",
  Intuition = "intuition",
  Learning = "learning",
  Leisure = "leisure",
  Power = "bolt",
  Principles = "principles",
  Relationships = "relationships",
  OnePrimaryFilledDot = "one-primary-filled-dot",
  TwoPrimaryFilledDot = "two-primary-filled-dot",
  ThreePrimaryFilledDot = "three-primary-filled-dot",
  OneFilledDot = "one-filled-dot",
  TwoFilledDot = "two-filled-dot",
  ThreeFilledDot = "three-filled-dot",
  TriangleExclamation = "triangle-exclamation",
  ExclamationTriangle = "exclamation-triangle",
  Check = "check",
  OneFilledMenu = "one-filled-menu",
  TwoFilledMenu = "two-filled-menu",
  ThreeFilledMenu = "three-filled-menu",
  ArrowTopRightOnSquare = "arrow-top-right-on-square",
  ArrowLeft = "arrow-left",
  Link = "link",
  Twitter = "twitter",
  UserGroups2 = "user-groups-2",
  Flag = "flag",
  Hello = "hello",
  DsNew = "ds-new",
  PresentationChartLine = "PresentationChartLine",
  Cog8Tooth = "Cog8Tooth",
  ChatBubbleBottomCenterText = "ChatBubbleBottomCenterText",
  GroupAssign = "GroupAssign",
  GroupUnassign = "GroupUnassign",
  FolderCheck = "FolderCheck",
  ShieldExclamation = "ShieldExclamation",
  ArrowsRightLeft = "ArrowsRightLeft",
  ArrowLongDown = "ArrowLongDown",
  ArrowLongUp = "ArrowLongUp",
  Sparkles = "sparkles",
  LinkedIn = "linkedin",
  Instagram = "instagram",
  TwitterFilled = "twitter-filled",
  Scale = "scale",
  Cube = "Cube",
  CubeActive = "CubeActive",
  CubeCustomActive = "CubeCustomActive",
  CubeCustomInactive = "CubeCustomInactive",
  LockSolid = "LockSolid",
  Tradition = "tradition",
  FolderClosed = "FolderClosed",
  CloseSidebar = "close-sidebar",
  Clock = "clock",
  CoachStarter = "dice-d4",
  CoachPlan = "dice-d8",
  CoachPlanPro = "dice-d10",
  CoachPlanMax = "dice-d12",
  CheckBadgeSolid = "check-badge-solid",
  CreditCard = "credit-card",
  Enterprise = "dice-d20",
  Upload = "upload",
  MagnifyingGlassCircle = "magnifying-glass-circle",
  MagnifyingGlassMinus = "magnifying-glass-minus",
  MagnifyingGlassPlus = "magnifying-glass-plus",
  MagnifyingGlass = "magnifying-glass",
  GlobeAlt = "globe-alt",
  Globe = "globe",
  Quotes = "quotes",
  Star = "star",
  StarSolid = "star-solid",
  Filter = "filter",
  FilterSolid = "FilterSolid",
  AccountCircle = "account_circle",
  UsersPlus = "UsersPlus",
  AccountTree = "account_tree",
  FaceDisappointed = "face-disappointed",
  FaceLaugh = "face-laugh",
  ArrowUpTray = "ArrowUpTray",
  DocumentArrowDown = "DocumentArrowDown",
  CoreDriversAssessmentIcon = "core-drivers-assessment-icon",
  CoreValuesAssessmentIcon = "core-values-assessment-icon",
  EllipsisVertical = "EllipsisVertical",
  AdminDashboardSolid = "AdminDashboardSolid",
  AdminDashboard = "AdminDashboard",
  Teams = "people-simple",
  AllAssessments = "message-question",
  Libraries = "rectangle-history",
  Analytics = "chart-line-up",
  Play = "play",
  PlaySolid = "PlaySolid",
  BarsStaggered = "bars-staggered",
  ArrowUpDown = "ArrowUpDown",
  Competencies = "Competencies",
  Insights = "lightbulb-on",
  DynamoCards = "rectangle-vertical-history",
  //Skills report
  AnalyticsEvaluator = "analytics-evaluator",
  OldAnalyticsEvaluator = "analytics_evaluator",
  BehavioralScientist = "behavioral-scientist",
  OldBehavioralScientist = "behavioral_scientist",
  OldChangeManager = "change_manager",
  ChangeManager = "change-manager",
  Consultant = "consultant",
  DataReportingAnalyst = "data-reporting-analyst",
  OldDataReportingAnalyst = "data_reporting_analyst",
  DataScientist = "data-scientist",
  OldDataScientist = "data_scientist",
  DataSteward = "data-steward",
  OldDataSteward = "data_steward",
  DesignEngineer = "design-engineer",
  OldDesignEngineer = "design_engineer",
  GovernanceEthicsSteward = "governance-ethics-steward",
  OldGovernanceEthicsSteward = "governance_ethics_steward",
  KpiMetricsAnalyst = "kpi-metrics-analyst",
  OldKpiMetricsAnalyst = "kpi_metrics_analyst",
  ProductManager = "product-manager",
  OldProductManager = "product_manager",
  ProjectManager = "project-manager",
  OldProjectManager = "project_manager",
  TextAnalysts = "text-analysts",
  OldTextAnalysts = "text_analysts",
  WorkforceModeller = "workforce-modeller",
  OldWorkforceModeller = "workforce_modeller",
  //HRSkills report
  OrganisationalAcumen = "organisational-acumen",
  OldOrganisationalAcumen = "organisational_acumen",
  StakeholderManagement = "stakeholder-management",
  OldStakeholderManagement = "stakeholder_management",
  Storytelling = "storytelling",
  HumanCentredDesign = "human-centred-design",
  OldHumanCentredDesign = "human_centred_design",
  ExImplementation = "ex-implementation",
  OldExImplementation = "ex_implementation",
  DigitalLiteracy = "digital-literacy",
  OldDigitalLiteracy = "digital_literacy",
  AnalyticalThinking = "analytical-thinking",
  OldAnalyticalThinking = "analytical_thinking",
  WorkforcePlanning = "workforce-planning",
  OldWorkforcePlanning = "workforce_planning",
  DataAnalysis = "data-analysis",
  OldDataAnalysis = "data_analysis",
  Reward = "reward",
  ThreeVerticalLines = "ThreeVerticalLines",
  Crowdin = "crowdin",
  LightBolt = "power",
  Candid = "candid",
  Considerate = "considerate",
  Curious = "curious",
  Disciplined = "disciplined",
  Driven = "driven",
  Flexible = "flexible",
  LaidBack = "laidBack",
  Outgoing = "outgoing",
  Passionate = "passionate",
  Pragmatic = "pragmatic",
  Reserved = "reserved",
  Stable = "stable",
  DefaultAssessment = "default-assessment-icon",
  PaperPlane = "paper-plane",
  PaperPlaneSolid = "paper-plane-solid",
  WavePulse = "wave-pulse",
  RectangleHistory = "rectangle-history",
  Timer = "timer",
  PeopleSimple = "PeopleSimple",
  Bell = "bell",
  NoSymbol = "no-symbol",
  Feather = "feather",
  SquarePollBars = "square-poll-bars",
  Tag = "tag",
  FACheck = "FACheck",
  ThreeColumns = "ThreeColumns",
  BarsFilter = "BarsFilter",
  LightBulbOn = "lightbulb-on",
  BuildingOffice2 = "building-office-2",
  NewsPaper = "newspaper",
  RectangleVerticalHistory = "rectangle-vertical-history",
  Dot = "dot",
  RotateLeft = "rotate-left",
  AllUsers = "user-group-simple",
  Cog = "cog",
  ObjectColumn = "objects-column",
  GraduationCap = "graduation-cap",
  MessageQuestion = "message-question",
  CircleInfo = "circle-info",
  PuzzlePiece = "puzzle-piece",
  ChartSimple = "chart-simple",
  Brain = "brain",
  FileArrowDown = "file-arrow-down",
  ArrowRightFromBracket = "arrow-right-from-bracket",
  LightbulbCflOn = "lightbulb-cfl-on",
  ArrowDownToLine = "arrow-down-to-line",
  Circle = "circle",
  ShareNodes = "share-nodes",
  ArrowTurnDownLeft = "arrow-turn-down-left",
  Clone = "clone",
  Xmark = "xmark",
  SquarePollVertical = "square-poll-vertical",
  PeopleGroup = "people-group",
  LassoSparkles = "lasso-sparkles",
  EllipsisV = "ellipsis-v",
  Archive = "archive",
  Pen = "pen",
  FloppyDisk = "floppy-disk",
  UserTie = "user-tie",
  Loader = "loader",
  BadgeCheck = "badge-check",
  CheckDouble = "check-double",
  ArrowsRotate = "arrows-rotate",
  ChevronsUp = "chevrons-up",
  EnvelopeDot = "envelope-dot",
  ChartScatter3d = "chart-scatter-3d",
  QuestionCircle = "question-circle",
}

export enum DsIconWeightEnum {
  Solid = "solid",
  Regular = "regular",
  Light = "light",
  Thin = "thin",
  Kit = "kit",
  DuotoneKit = "kit-duotone",
}

import { Component, Input, OnInit, Signal, computed, input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { NgHeroiconsModule } from "@dimaslz/ng-heroicons";

import { DsIconEnum, DsIconWeightEnum } from "./ds-icon.models";
@Component({
  selector: "ds-icon",
  templateUrl: "./ds-icon.component.html",
  imports: [NgHeroiconsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, NgClass],
  standalone: true,
  host: {
    "[class]": "hostClasses",
  },
})
export class DsIconComponent implements OnInit {
  @Input() iconSvg: string;
  @Input() color: string;
  @Input() size: number = 22;
  @Input() stroke: number = 1;
  @Input() class: string;

  name = input<string>();
  isWebfont = input<boolean>();
  webfontWeight = input<DsIconWeightEnum>();
  webfontCustomClasses = input<string>();

  hostClasses: string = "";
  fontAwesomeIconClass: Signal<string> = computed(() => {
    if (!this.isWebfont()) return;
    let icon = "";
    icon += ` fa-${this.name()}`;
    if (this.webfontWeight()) {
      icon += ` fa-${this.webfontWeight()}`;
    }

    if (this.webfontCustomClasses()) {
      icon += ` ${this.webfontCustomClasses()}`;
    }

    return icon;
  });

  DsIconEnum = DsIconEnum;

  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    if (this.isWebfont()) this.hostClasses = "flex";
  }

  parseIcon(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  getIconStyle(): string {
    let style: string[] = [];

    if (this.size) {
      style.push(`width: ${this.size}px; height: ${this.size}px;`);
    }
    if (this.stroke) {
      style.push(`stroke-width: ${this.stroke}px;`);
    }

    return style.join(" ");
  }
}
